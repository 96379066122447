import { render, staticRenderFns } from "./ButtonPreview.vue?vue&type=template&id=71cc6ef4&scoped=true&"
import script from "./ButtonPreview.vue?vue&type=script&setup=true&lang=ts&"
export * from "./ButtonPreview.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./ButtonPreview.vue?vue&type=style&index=0&id=71cc6ef4&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71cc6ef4",
  null
  
)

export default component.exports